import { Button } from "@mui/material";
import { useHasUserCard } from "Hooks/Queries/User/useHasUserCard";
import { useGetUserQuery } from "Reducers/ApiSlice";
import { memo } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

export const VitaButton = memo(function VitaButtonComponent({
  variant = "default",
  className = "",
  span,
}: {
  variant?: string;
  className?: string;
  span?: string;
}) {
  const { error } = useGetUserQuery();
  const { userHasCard } = useHasUserCard(error?.isAuthorized);

  const btnclass =
    variant == "default"
      ? "bg-green-100 border-none text-[#fff] hover:text-green-100 hover:bg-green-60"
      : "bg-[#fff] border-[2px] border-solid border-green-100 text-green-100";

  const Btn = () => (
    <Button
      id="buycard"
      aria-label="vita"
      variant="contained"
      className={`${btnclass} ${className} overflow-hidden group vtbtn relative py-[18px] px-[36px] rounded-[12px] shadow-none text-md font-demi leading-[24px] pointer transition-all`}
    >
      <span
        className={`${span} relative z-10 flex items-center w-full min-w-full gap-2`}
      >
        შეიძინე ბარათი
        <ReactSVG
          className={`${variant == "default" ? "" : "svg-filter"} svgfilter`}
          src="/images/icons/rotatedarrow.svg"
        />
      </span>
      {variant == "default" && (
        <div className="group-hover:translate-x-[102.3%] absolute left-[-100%] bg-[#fff] w-[98.5%] h-[92%] top-[50%] translate-y-[-50%] rounded-[12px] transition-[0.6s]" />
      )}
    </Button>
  );
  return error?.isAuthorized ? (
    userHasCard == true ? (
      <Link to={"/user/card"}>
        <Btn />
      </Link>
    ) : (
      <Link to={"/checkout"}>
        <Btn />
      </Link>
    )
  ) : (
    <a href={error?.skeleton == true ? "/" : "/secured"}>
      <Btn />
    </a>
  );
});
